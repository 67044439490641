import { Address } from '../customer/address'

export const comparableAddressFields: string[] = [
  'firstname',
  'lastname',
  'postcode',
  'street',
  'city',
  'telephone',
  'company',
  'country_id',
  'region',
  'region_code',
  'region_id',
];

export const postcodeMaxLengthByCountryId = (countryId: string): number => {
  switch (countryId) {
    case 'NL':
      return 6;
    case 'GB':
      return 7;
    case 'DE':
      return 5;
    case 'BE':
      return 4;
    default:
      return 10;
  }
}

export const postcodeMinLengthByCountryId = (countryId: string): number => {
  switch (countryId) {
    case 'DE':
      return 5;
    case 'BE':
      return 4;
    default:
      return 0;
  }
}

export const formCodeByLocale = (locale: string): string => {
  switch (locale) {
    case 'nl_NL':
    case 'NL':
      return 'checkout-address-form-nl'
    case 'nl_BE':
    case 'BE':
      return 'checkout-address-form-be'
    case 'de_DE':
    case 'DE':
      return 'checkout-address-form-de'
    case 'sv_SE':
    case 'SE':
      return 'checkout-address-form-se'
    default:
      return 'checkout-address-form-default'
  }
}

export const flattenRegionForAddress = (address: object): object => {
  let _address = {...address}
  const region = _address['region'] || {}

  if ('object' === typeof region) {
    delete _address['region']
    _address = Object.assign(_address, region)
  }

  return _address
}

export const serializeAddressField = (value: any): any => {
  if ('object' === typeof value) {
    return JSON.stringify({...value})
  }

  return value
}

export const isShippingAddressBillingAddress = (shippingAddress: Address, billingAddress: Address): boolean => {
  for (const field in comparableAddressFields) {
    if (serializeAddressField(shippingAddress[field]) !== serializeAddressField(billingAddress[field])) {
      return false
    }
  }

  return true
}
