export default {
    "Categories": "Kategorier",
    "Search results for &ldquo;{query}&rdquo;": "S\u00f6kresultat f\u00f6r &ldquo;{query}&ldquo;",
    "What do our customers say": "Vad s\u00e4ger v\u00e5ra kunder",
    "{shop} scores a {average} based on {total} reviews": "shop f\u00e5r ett {average} baserat p\u00e5 {total} recensioner",
    "By": "Av",
    "Subscribe": "Prenumerera",
    "Created by": "Skapad av",
    "{count} ratings": "Se recensioner",
    "Alternate Products": "Alternativa produkter",
    "Product Description": "Produktbeskrivning",
    "Specifications": "Specifikationer",
    "Related Products": "Relaterade produkter",
    "Size Chart": "Storlekstabell",
    "Size Advice": "Storleksr\u00e5d",
    "Reviews": "Recensioner",
    "All about this product": "Allt om den h\u00e4r produkten",
    "All about the {name}": "Allt om {name}",
    "Read more": "L\u00e4s mer",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Betyg av <span>&rdquo;{name}&ldquo;</span>",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> baserat p\u00e5 <span>{count}</span> recensioner",
    "Written by {name} on {date}": "Skriven av {name} p\u00e5 {date}",
    "Thank you for your order!": "Tack f\u00f6r din best\u00e4llning!",
    "Your order has been placed successfully.": "Din best\u00e4llning har mottagits i god ordning.",
    "Your ordernumber is: #{order_id}.": "Ditt ordernummer \u00e4r#{order_id}.",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Du kommer att f\u00e5 ett e-mail som bekr\u00e4ftar din best\u00e4llning med alla detaljer inom 15 minuter. Kontrollera \u00e4ven din skr\u00e4ppost om du inte har f\u00e5tt ett bekr\u00e4ftelsemeddelande efter 15 minuter. Om du har n\u00e5gra fr\u00e5gor om status f\u00f6r din best\u00e4llning \u00e4r det b\u00e4st att kontakta kundtj\u00e4nst",
    "Billing address": "Fakturaadress",
    "Shipping address": "Leveransadress",
    "Pick up your order at:": "H\u00e4mta din best\u00e4llning p\u00e5",
    "Recent Blog Posts": "Senaste blogginl\u00e4gg",
    "Previous": "F\u00f6reg\u00e5ende",
    "Next": "N\u00e4sta",
    "Tags": "Taggar",
    "Products by this post": "Produkter av detta inl\u00e4gg",
    "Login": "Logga in",
    "Email address": "E-postadress",
    "Password": "L\u00f6senord",
    "Confirm password": "Bekr\u00e4fta l\u00f6senord",
    "Forgot password?": "Har du gl\u00f6mt ditt l\u00f6senord?",
    "Benefits of an account": "F\u00f6rdelar med ett konto",
    "Create account": "Skapa konto",
    "You are now signed out": "Du \u00e4r nu utloggad",
    "You are now logged out and will return to our home page within 5 seconds.": "Du \u00e4r nu utloggad och kommer tillbaka till v\u00e5r hemsida inom 5 sekunder.",
    "First name": "F\u00f6rnamn",
    "Last name": "Efternamn",
    "Date of birth": "F\u00f6delsedatum",
    "DD": "DD",
    "MM": "MM",
    "YYYY": "YYYY",
    "Male": "Man",
    "Female": "Kvinna",
    "Subscribe to our newsletter": "Prenumerera p\u00e5 v\u00e5rt nyhetsbrev",
    "Welcome {name}": "V\u00e4lkommen {namn}",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Denna personliga sida ger dig en \u00f6versikt \u00f6ver de senaste aktiviteterna. Du kan bland annat se best\u00e4llningar och uppdatera kontoinformation. Klicka p\u00e5 l\u00e4nken nedan f\u00f6r att komma ig\u00e5ng!",
    "Recent orders": "Senaste order",
    "You have not placed any orders yet": "Du har inte placerat n\u00e5gra order \u00e4nnu",
    "Order #": "Order #",
    "Date": "Datum",
    "Ship to": "Skicka till",
    "Total": "Totalt",
    "View order": "Se order",
    "Personal settings": "Personliga inst\u00e4llningar",
    "Contact details": "Kontaktuppgifter",
    "Edit": "Redigera",
    "Change password": "Byt l\u00f6senord",
    "Newsletter": "Nyhetsbrev",
    "You are subscribed to our newsletter": "Du prenumererar p\u00e5 v\u00e5rt nyhetsbrev",
    "subscribe": "prenumerera",
    "Addresses": "Adresser",
    "Default shipping address": "Standard leveransadress",
    "No default shipping address available": "Ingen standard leveransadress tillg\u00e4nglig",
    "Default billing address": "Standard faktureringsadress",
    "No default billing address available": "Ingen standardfaktureringsadress tillg\u00e4nglig",
    "Edit personal settings": "Redigera personliga inst\u00e4llningar",
    "Current password": "Nuvarande l\u00f6senord",
    "New password": "Nytt l\u00f6senord",
    "Confirm new password": "Bekr\u00e4fta nytt l\u00f6senord",
    "Save": "Spara",
    "Add new address": "L\u00e4gg till ny adress",
    "Company": "F\u00f6retag",
    "Phone number": "Telefonnummer",
    "Address": "Adress",
    "Postal code + house number": "Postnummer + husnummer",
    "Postal code": "Postnummer",
    "House no.": "Husnummer",
    "Street": "Gata",
    "City": "Stad",
    "Country": "Land",
    "Select a country": "V\u00e4lj ett land",
    "Save address": "Spara adress",
    "Edit address": "L\u00e4gg till adress",
    "Extra Addresses": "Extra adress",
    "No addresses available": "Inga adresser tillg\u00e4ngliga",
    "Remove": "Ta bort",
    "Account overview": "Konto \u00f6versikt",
    "My orders": "Mina best\u00e4llningar",
    "My reviews": "Mina recensioner",
    "Newsletters": "Nyhetsbrev",
    "Wishlist": "\u00d6nskelista",
    "Logout": "Logga ut",
    "Do you receive our newsletter?": "F\u00e5r du v\u00e5rt nyhetsbrev?",
    "Order {increment}": "Order {increment}",
    "Shipping method": "Frakts\u00e4tt",
    "Payment method": "Betalningsmetod",
    "Ordered items": "best\u00e4llda varor",
    "Item": "Artikel",
    "SKU": "SKU",
    "Price": "Pris",
    "Quantity": "Antal",
    "Subtotal": "Delsumma",
    "Shipping": "Frakt",
    "Free": "Gratis",
    "Grand total": "Totalsumma",
    "Request password": "Beg\u00e4r l\u00f6senord",
    "You have not reviewed any products yet": "Du har inte granskat n\u00e5gra produkter \u00e4nnu",
    "Previously viewed by you": "Tidigare sett av dig",
    "Apply": "Applicera",
    "Cancel": "Avbryt",
    "Search for...": "S\u00f6ka efter\u2026",
    "More results for {inputQuery}": "Fler resultat f\u00f6r {inputQuery}",
    "No results found for {inputQuery}": "Inga resultat hittades f\u00f6r {inputQuery}",
    "Select an option": "V\u00e4lj ett alternativ",
    "More": "Mer",
    "Be inspired": "Bli inspirerad",
    "Active filters": "Aktiva filter",
    "More options": "Mer",
    "Less options": "Mindre",
    "to": "Till",
    "Enter a correct value": "Ange ett korrekt v\u00e4rde",
    "Sizes": "Storlekar",
    "Filter": "Filter",
    "Show results": "Visa resultat",
    "Show {totalItems} results": "Inga resultat | 1 resultat | {count} resultat",
    "No products were found in this category.": "Inga produkter hittades i denna kategori",
    "{count} results": "Inga resultat | 1 resultat | {count} resultat",
    "Sort by": "Sortera efter",
    "Latest releases": "senaste nyheterna",
    "Name (A-Z)": "Namn (A-Z)",
    "Name (Z-A)": "Namn (Z-A)",
    "Price (Ascending)": "Pris (stigande)",
    "Price (Descending)": "Pris (fallande)",
    "Choose your {option}": "V\u00e4lj din {option}",
    "Notify me when my size is back in stock": "Meddela mig n\u00e4r min storlek finns i lager igen",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Tack f\u00f6r din f\u00f6rfr\u00e5gan, du kommer att meddelas via e-post n\u00e4r storleken finns i lager igen!",
    "Out of stock notification": "Meddelande om slut i lager",
    "Choose your size": "V\u00e4lj din storlek",
    "Send": "Skicka",
    "You don't seem to be logged in, please login to enable stock notifications.": "Du verkar inte vara inloggad, logga in f\u00f6r att aktivera lageraviseringar.",
    "If you don't already have an account, you can register one {here}": "Om du inte redan har ett konto kan du registrera ett {here}",
    "here": "h\u00e4r",
    "By {name} on {date}": "Av {name} p\u00e5 date",
    "Added to cart": "L\u00e4ggs till i kundvagnen",
    "To shopping cart": "Till varukorgen",
    "Nice to add": "Trevligt att l\u00e4gga till",
    "You may also like this": "Du kanske ocks\u00e5 gillar det h\u00e4r",
    "Select one of the required options.": "V\u00e4lj en av de obligatoriska alternativen.",
    "Choose your personalisation options": "V\u00e4lj ditt tryck",
    "Personalize product": "Trycka",
    "Personalize product with name and/or number": "Tryck produkten med namn och / eller nummer!",
    "Choose your stringing personalisation options": "V\u00e4lj din typ av str\u00e4ngning",
    "Personalize product with stringing": "Str\u00e4ngningsservice",
    "Personalize product with stringing and/or weight": "Anpassa racket med str\u00e4ngval och vikt",
    "Snaar": "Typ av str\u00e4ng",
    "Gewicht": "H\u00e5rdhet",
    "Choose {option}": "V\u00e4lj {option}",
    "Add to cart": "L\u00e4gg till i varukorgen",
    "Featured": "Rekommenderas",
    "Pay safe": "Betala s\u00e4kert",
    "Write a review": "Skriva en recension",
    "Your rating": "Ditt betyg",
    "name": "Namn",
    "Enter your name": "Ange ditt namn",
    "description": "beskrivning",
    "Short description of your review": "Kort beskrivning av din recension",
    "review": "recension",
    "Submit review": "Skicka recension",
    "Unable to save review": "Det g\u00e5r inte att spara recension",
    "Thanks for placing a review!": "Tack f\u00f6r att du skrev en recension!",
    "Shopping cart": "Varukorg",
    "To checkout": "Till kassan",
    "One or more products are out of stock.": "En eller flera produkter \u00e4r slut i lager.",
    "Continue shopping": "Forts\u00e4tt handla",
    "You have no product(s) in your shopping cart.": "Du har ingen produkt (er) i din varukorg.",
    "Click {url} to continue shopping": "Klicka {url} f\u00f6r att forts\u00e4tta handla",
    "Loading shopping cart items...": "Laddar varor i varukorgen...",
    "Product out of stock": "Produkten \u00e4r slut i lager",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Best\u00e4llt f\u00f6re 22:00, <span>levereras gratis</span>n\u00e4sta dag",
    "Sold out! Will be expected at <span>{date}</span>.": "Tillf\u00e4lligt sluts\u00e5ld! v\u00e4ntas p\u00e5 <span>{date}.</span>",
    "Will be expected at <span>{date}</span>.": "v\u00e4ntas p\u00e5 <span>{date}</span>.",
    "Sold out!": "Produkten \u00e4r tyv\u00e4rr sluts\u00e5ld",
    "Expected delivery: {date}": "F\u00f6rv\u00e4ntad leverans {date} dagar",
    "Delivery costs": "Fraktkostnad",
    "Discount": "Du sparar",
    "Payment": "Betalning",
    "Complete!": "Klar!",
    "Log in": "Logga in",
    "Name": "Namn",
    "Choose your country": "V\u00e4lj ditt land",
    "House number": "Husnummer",
    "Addition": "Till\u00e4gg",
    "Add.": "L\u00e4gg till.",
    "Telephone": "Telefon",
    "Click here to automatically retrieve your address": "Klicka h\u00e4r f\u00f6r att automatiskt h\u00e4mta din adress",
    "We have found the following address": "Vi har hittat f\u00f6ljande adress",
    "Invalid address. Are you sure it is correct?": "Ogiltig adress. \u00c4r du s\u00e4ker p\u00e5 att det \u00e4r r\u00e4tt?",
    "Click here to enter the address manually.": "Klicka h\u00e4r f\u00f6r att ange adressen manuellt.",
    "Select your address": "V\u00e4lj din adress",
    "Login with your account": "Logga in med ditt konto",
    "You have an account. Would you like to {action}?": "Du har ett konto.Skulle du vilja {action}?",
    "login": "Logga in",
    "I want to add a comment": "Jag vill l\u00e4gga till en kommentar",
    "Write your comment": "Skriv din kommentar",
    "Without extra fees": "Utan extra avgifter",
    "Credit card": "Kreditkort",
    "Choose your credit card": "V\u00e4lj ditt kreditkort",
    "The most used payment method in The Netherlands": "Den mest anv\u00e4nda betalningsmetoden i Sverige",
    "Bank": "Bank",
    "Choose your bank": "V\u00e4lj din bank",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Har du redan ett konto hos oss? Logga in {action} s\u00e5 f\u00e5r vi dina uppgifter.",
    "Billing address is different from the address above": "Faktureringsadressen skiljer sig fr\u00e5n adressen ovan",
    "Sign me up for the newsletter": "Anm\u00e4l mig till nyhetsbrevet",
    "Create an account": "Skapa ett konto",
    "Payment methods": "Betalningsmetoder",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "N\u00e5got gick fel, betalningen kunde inte slutf\u00f6ras. F\u00f6rs\u00f6k igen eller v\u00e4lj en annan betalningsmetod.",
    "Choose a payment method to complete your order": "V\u00e4lj en betalningsmetod f\u00f6r att slutf\u00f6ra din best\u00e4llning",
    "Shipping methods": "Fraktmetoder",
    "Choose a delivery method to proceed to the next step": "V\u00e4lj en leveransmetod f\u00f6r att g\u00e5 vidare till n\u00e4sta steg",
    "Overview of your order": "\u00d6versikt \u00f6ver din best\u00e4llning",
    "You can pick up your order at": "Du kan h\u00e4mta din best\u00e4llning p\u00e5",
    "Your order will be delivered to": "Din best\u00e4llning kommer att levereras till",
    "Change delivery address": "\u00c4ndra leveransadress",
    "Some items in your shopping cart are out of stock. {action}": "Vissa varor i din varukorg \u00e4r slut i lager.{action}",
    "View products.": "Visa varor",
    "Previous step": "f\u00f6reg\u00e5ende steg",
    "To shipping": "Till leverans",
    "Continue": "Forts\u00e4tt",
    "Complete order": "Komplett best\u00e4llning",
    "I have a discount code": "Jag har en rabattkod",
    "Your applied coupon code is {couponCodeInput}.": "Rabattkoden du har lagt till \u00e4r {couponCodeInput}.",
    "Select a {carrier} pick-up point": "V\u00e4lj en {carrier} pick-up point",
    "Please wait, we are loading the stores ...": "Ett \u00f6gonblick, laddar...",
    "personal ID": "Personnummer",
    "{distance} kilometer": "{distance} kilometer",
    "{distance} meter": "{distance} meter",
    "Opening hours": "\u00d6ppettider",
    "Enter your postal code": "Ange ditt postnummer",
    "My wishlist": "Min \u00f6nskelista",
    "There are no products in your wishlist": "Det finns inga produkter i din \u00f6nskelista",
    "Request return": "Beg\u00e4r retur",
    "Sign up": "Anm\u00e4l dig",
    "Conditions": "Villkor",
    "Instructions": "Instruktioner",
    "Thanks": "Tack",
    "Order number": "Ordernummer",
    "Submit complaint": "Skicka klagom\u00e5l",
    "Order": "Order",
    "Order Date:": "orderdatum",
    "Items ordered": "Best\u00e4llda varor",
    "Product": "Produkt",
    "Qty": "Antal",
    "Return": "Retur",
    "Do you have a comment for this return?": "Har du en kommentar f\u00f6r denna retur?",
    "Submit return": "Skicka retur",
    "Accept terms and conditions on return submit note": "Acceptera villkoren vid retur skicka notering",
    "Print return ticket": "Skriv ut retursedel",
    "Finalize return": "Slutf\u00f6r retur",
    "Upload image": "Ladda upp bild",
    "No image chosen": "Ingen bild vald",
    "Complaint is a required field.": "Klagom\u00e5l \u00e4r ett obligatoriskt f\u00e4lt.",
    "Something went wrong, please try again later": "N\u00e5got gick fel, f\u00f6rs\u00f6k igen senare",
    "ordered": "Best\u00e4llt",
    "Can't be returned": "Kan inte returneras",
    "Already returned": "Redan returnerat",
    "Message": "Meddelande",
    "Submit": "L\u00e4gg till",
    "Contact us": "Kontakta oss",
    "Invalid re-captcha.": "Ogiltig re-captcha",
    "Your message has been sent.": "Ditt meddelande har skickats.",
    "validation.required": "validation.required",
    "validation.email": "Ogiltig e-postadress f\u00f6r f\u00e4lt '{attribute}\u2019",
    "All {label}": "All {label}",
    "Choose your": "V\u00e4lj din",
    "Grand Total": "Totalsumma",
    "Forgot password": "\u00c5terst\u00e4ll l\u00f6senordet",
    "Successfully created an account, you can log in with your new credentials": "Successfully created an account, you can log in with your new credentials",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Om ett konto hittas med {email} f\u00e5r du ett e-postmeddelande med en l\u00e4nk f\u00f6r att \u00e5terst\u00e4lla ditt l\u00f6senord.",
    "Thank you for your order, {name}": "Tack f\u00f6r din best\u00e4llning {name}",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "Du kommer snart att f\u00e5 ett e-postmeddelande med information om din best\u00e4llning. F\u00f6r fr\u00e5gor om din best\u00e4llning, bes\u00f6k v\u00e5r <a href=\"/contact\"> kundtj\u00e4nst </a> och kontakta oss!",
    "Your order number is: <strong>#{orderid}</strong>": "Din Bestellnummer \u00e4r: <strong>#{orderid}</strong>",
    "Best regards, {store}": "Med v\u00e4nliga h\u00e4lsningar {store}",
    "We expect to deliver on": "Vi f\u00f6rv\u00e4ntar oss att leverera vidare",
    "We will send a confirmation by e-mail to": "Vi skickar en bekr\u00e4ftelse via e-post till",
    "Do you have any questions?": "Har du n\u00e5gra fr\u00e5gor?",
    "Visit our <a href=\"/contact\"> customer service </a>": "Bes\u00f6k v\u00e5r <a href=\"/contact\"> kundtj\u00e4nst </a>",
    "Prefix": "H\u00e4lsning",
    "Sir.": "Herr.",
    "Mrs.": "Fru.",
    "Birthday": "F\u00f6delsedag",
    "I accept the Riverty {url} *": "Jag accepterar Riverty {url} *",
    "terms of payment": "betalningsvillkor",
    "Monday": "m\u00e5ndag",
    "Tuesday": "tisdag",
    "Wednesday": "onsdag",
    "Thursday": "torsdag",
    "Friday": "fredag",
    "Saturday": "l\u00f6rdag",
    "Sunday": "s\u00f6ndag",
    "Closed": "St\u00e4ngd",
    "recommended": "V\u00e5rt tips",
    "This is a secure connection": "Detta \u00e4r en s\u00e4ker anslutning",
    "tomorrow": "imorgon",
    "Create an account for your next visit!": "Skapa ett konto f\u00f6r ditt n\u00e4sta bes\u00f6k!",
    "The benefits of an account:": "F\u00f6rdelarna med ett konto:",
    "Order comments": "Best\u00e4ll kommentarer",
    "contact_form.subject": "Kontaktformul\u00e4r {store}Name",
    "Search results": "S\u00f6kresultat",
    "Recommended": "Rekommenderad",
    "new": "Ny",
    "Pickup point": "Pickup point",
    "Something went wrong while restoring your password": "N\u00e5got gick fel n\u00e4r du \u00e5terst\u00e4llde ditt l\u00f6senord",
    "Your password has been updated": "ditt l\u00f6senord har uppdaterats",
    "Please enter a valid code": "Ange en giltig kod",
    "The coupon code could not be cancelled, please try again later.": "Kupongkoden kunde inte annulleras. F\u00f6rs\u00f6k igen senare.",
    "Choose your FREE product": "V\u00e4lj din gratisprodukt",
    "Status": "Status",
    "Used coupon": "Anv\u00e4nd kupongkoden",
    "Click here to follow your order": "Klicka h\u00e4r f\u00f6r att sp\u00e5ra din best\u00e4llning",
    "My account": "Mitt konto",
    "Reset password": "\u00c5terst\u00e4ll l\u00f6senordet",
    "Cart": "Vagn",
    "Checkout": "Kolla upp",
    "Download invoice": "Ladda ner faktura",
    "Choose your color": "V\u00e4lj din f\u00e4rg",
    "Color": "F\u00e4rg",
    "Sorry we're down for maintenance": "Vi ber om urs\u00e4kt, v\u00e5r shop \u00e4r offline f\u00f6r tillf\u00e4llet p\u00e5 grund av underh\u00e5ll. ",
    "We'll be back shortly": "Vi hoppas vara online igen s\u00e5 snart som m\u00f6jligt.",
    "Your recommended size is currently not available!": "Din rekommenderade storlek \u00e4r f\u00f6r n\u00e4rvarande inte tillg\u00e4nglig!",
    "The cache of {cache} has been emptied": "Cachen f\u00f6r {cache} har raderats",
    "The cache could not be cleared": "Cachen kunde inte rensas",
    "The coupon code isn't valid. Verify the code and try again.": "Kupongkoden \u00e4r inte giltig. Verifiera koden och f\u00f6rs\u00f6k igen.",
    "All fields must be filled": "Alla f\u00e4lt m\u00e5ste fyllas i.",
    "To Kiyoh reviews page": "Ge din \u00e5sikt",
    "You already have an account with us. Please log in or continue without an account.": "Du har redan ett konto hos oss. V\u00e4nligen logga in eller forts\u00e4tt utan konto.",
    "You can create an account after you\u2019ve purchased the order.": "Du kan skapa ett konto efter avslutat k\u00f6p.",
    "Did you mean {email}?": "Menade du {email}?",
    "Delete account": "Radera konto",
    "Yes, I want to permanently delete my account": "Ja, jag vill ta bort mitt konto permanent",
    "Add set to cart": "L\u00e4gg till alla set i varukorgen",
    "{name} has been added to your cart.": "{name} har lagts till i din varukorg.",
    "Max {count} characters": "Max {count} tecken",
    "Personalisation Title": "Ihr Produkt personalisieren",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Anv\u00e4ndarnamn eller l\u00f6senord \u00e4r fel",
    "Repeat email address": "Upprepa din e-postadress",
    "E-mail addresses do not match.": "E-postadresserna st\u00e4mmer inte \u00f6verens.",
    "Store": "Lagra",
    "Order for your team": "Best\u00e4ll f\u00f6r ditt team",
    "Add player | Add players": "L\u00e4gg till spelare | L\u00e4gg till spelare",
    "Player": "Spelare",
    "Check if all players meet the required options.": "Kontrollera om alla spelare uppfyller de obligatoriska alternativen.",
    "Not all sizes are selected.": "Inte alla storlekar \u00e4r valda.",
    "Swipe for more products": "Svep f\u00f6r fler produkter",
    "Order this set for your entire team!": "Best\u00e4ll detta set f\u00f6r hela ditt lag!",
    "{qty} pieces for {price} each": "{qty} stycken f\u00f6r {price} varje",
    "Tierprices": "Volymrabatt",
    " - {stock} in stock": " - {stock} i lager",
    "Choose your delivery time:": "V\u00e4lj din leveransdag:",
    "Loading totals summary...": "laddar...",
    "Only {qty} available!": "Endast {qty} tillg\u00e4ngliga!",
    "Something went wrong, contact us for help.": "N\u00e5got gick fel, kontakta oss f\u00f6r hj\u00e4lp.",
    "Show more": "Visa mer",
    "Other sports": "Andra sporter",
    "Billing- and/or shipping address is not valid": "Kontrollera dina adressuppgifter",
    "Back": "Tillbaka",
    "How does it work?": "Hur fungerar det?",
    "Remove product": "Ta bort produkt",
    "Add product": "L\u00e4gg till produkt",
    "Desired delivery day": "\u00d6nskad leveransdag",
    "This product is in stock": "Denna produkt finns i lager",
    "Available sizes": "Tillg\u00e4ngliga storlekar",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "Minsta l\u00e4ngden f\u00f6r detta f\u00e4lt m\u00e5ste vara lika med eller st\u00f6rre \u00e4n {length} tecken. Ledande och avslutande mellanslag ignoreras.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "L\u00f6senordet m\u00e5ste inneh\u00e5lla minst {count} olika teckenklasser. Teckenklasser: Gemener, Versaler, Siffror, Specialtecken.",
    "The first name is required.": "F\u00f6rnamnet \u00e4r obligatoriskt.",
    "The first name must be a valid string.": "F\u00f6rnamnet m\u00e5ste vara en giltig str\u00e4ng.",
    "The first name must be at least 2 characters long.": "F\u00f6rnamnet m\u00e5ste vara minst 2 tecken l\u00e5ngt.",
    "The first name must not exceed 255 characters.": "F\u00f6rnamnet f\u00e5r inte vara l\u00e4ngre \u00e4n 255 tecken.",
    "The last name is required.": "Efternamnet \u00e4r obligatoriskt.",
    "The last name must be a valid string.": "Efternamnet m\u00e5ste vara en giltig str\u00e4ng.",
    "The last name must be at least 2 characters long.": "Efternamnet m\u00e5ste vara minst 2 tecken l\u00e5ngt.",
    "The last name must not exceed 255 characters.": "Efternamnet f\u00e5r inte vara l\u00e4ngre \u00e4n 255 tecken.",
    "The email address is required.": "E-postadressen \u00e4r obligatorisk.",
    "Please provide a valid email address.": "V\u00e4nligen ange en giltig e-postadress.",
    "The email address must not exceed 255 characters.": "E-postadressen f\u00e5r inte vara l\u00e4ngre \u00e4n 255 tecken.",
    "The day of birth is required.": "F\u00f6delsedagen \u00e4r obligatorisk.",
    "The day of birth must be a number.": "F\u00f6delsedagen m\u00e5ste vara ett nummer.",
    "The day of birth must be at least 1.": "F\u00f6delsedagen m\u00e5ste vara minst 1.",
    "The month of birth is required.": "F\u00f6delsem\u00e5naden \u00e4r obligatorisk.",
    "The month of birth must be a number.": "F\u00f6delsem\u00e5naden m\u00e5ste vara ett nummer.",
    "The month of birth must be at least 1.": "F\u00f6delsem\u00e5naden m\u00e5ste vara minst 1.",
    "The year of birth is required.": "F\u00f6delse\u00e5ret \u00e4r obligatoriskt.",
    "The year of birth must be a number.": "F\u00f6delse\u00e5ret m\u00e5ste vara ett nummer.",
    "The year of birth must be at least 4 digits long.": "F\u00f6delse\u00e5ret m\u00e5ste vara minst 4 siffror l\u00e5ngt.",
    "The gender must be either Male or Female.": "K\u00f6net m\u00e5ste vara antingen man eller kvinna.",
    "The password is required.": "L\u00f6senordet \u00e4r obligatoriskt.",
    "The password confirmation does not match.": "L\u00f6senordsbekr\u00e4ftelsen matchar inte.",
    "As early as possible": "S\u00e5 tidigt som m\u00f6jligt",
    "We expect to deliver your complete order on {date}": "Vi f\u00f6rv\u00e4ntar oss att leverera din kompletta order p\u00e5 {date}",
    "The payment provider rejected the payment request, please use another payment method.": "Betalningsleverant\u00f6ren avvisade betalningsbeg\u00e4ran, v\u00e4nligen anv\u00e4nd en annan betalningsmetod.",
    "The payment is canceled, the order is not paid": "Betalningen \u00e4r avbruten, best\u00e4llningen \u00e4r inte betald",
    "An error occurred during the payment process, please try again or use another payment method.": "Ett fel uppstod under betalningsprocessen, f\u00f6rs\u00f6k igen eller anv\u00e4nd en annan betalningsmetod.",
    "Returns": "Returer",
    "Search your orders": "S\u00f6k order",
    "Search": "S\u00f6k",
    "No order found with order id {orderId}": "Ingen order hittades med order id {orderId}",
    "Order placed on": "Order placerad p\u00e5",
    "An error occurred while fetching the order. Please try again later.": "Ett fel uppstod n\u00e4r ordern h\u00e4mtades. F\u00f6rs\u00f6k igen senare.",
    "Choose return reason": "V\u00e4lj returorsak",
    "Size": "Storlek",
    "I want to return my products": "Jag vill returnera mina produkter",
    "By initiating a return, you agree to our return policy.": "Genom att initiera en retur godk\u00e4nner du h\u00e4rmed v\u00e5r returpolicy.",
    "Download return label": "Ladda ner returfraktsedel",
    "This product has custom personalisation options.": "Denna produkt har anpassade personifieringsalternativ.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "Ett fel uppstod n\u00e4r ordern h\u00e4mtades. Kontrollera dina uppgifter eller f\u00f6rs\u00f6k igen senare.",
    "Oops, something went wrong": "Hoppsan, n\u00e5got gick fel",
    "My returns": "Mina returer",
    "Why?": "Varf\u00f6r?",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "Vi rekommenderar att du best\u00e4ller <strong>en storlek st\u00f6rre</strong> \u00e4n du normalt b\u00e4r.",
    "Do you want to merge your old and new shopping basket?": "Vill du sl\u00e5 samman dina varukorgar?",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "Du har flera produkter i din kundvagn. Vill du l\u00e4gga till dem tillsammans med de nya produkterna?",
    "No. Only continue with newly added items": "Nej, forts\u00e4tt med ny varukorg",
    "Yes. Merge and continue.": "Ja, l\u00e4gg till och forts\u00e4tt",
    "Successfully saved address": "Adress sparad",
    "I would like to:": "Jag vill:",
    "Track my order": "Sp\u00e5ra min order",
    "Download my invoice": "Ladda ner min faktura",
    "Return my products": "Returnera mina produkter",
    "An error occurred while fetching the return label. Please try again later.": "Ett fel uppstod n\u00e4r returetiketten h\u00e4mtades. F\u00f6rs\u00f6k igen senare.",
    "Read our return policy": "L\u00e4s v\u00e5r returpolicy",
    "We still have these colors for size {size} in stock!": "Vi har fortfarande den h\u00e4r f\u00e4rgen f\u00f6r storlek {size} i lager! | Vi har fortfarande dessa f\u00e4rger f\u00f6r storlek {size} i lager!",
    "Your last chosen payment method": "Din senast valda betalningsmetod",
    "in {label}": "i {label}"
}