const initialState = () => {
  return {
    categoryInlineFilterList: [],
    categoryInlineFilterFullList: [],
    hasWidgetActive: false,
  }
};

const state = initialState();

const mutations = {
  SET_INLINE_FILTER_LIST(state, payload) {
    state.categoryInlineFilterList = payload;
  },
  SET_INLINE_FILTER_FULL_LIST(state, payload) {
    state.categoryInlineFilterFullList = payload;
  },
  SET_HAS_WIDGET_ACTIVE(state, payload) {
    state.hasWidgetActive = !!payload;
  }
};

const actions = {
  async fetchInlineFilters({commit, dispatch, rootGetters}, categoryId) {
    //check for active filters
    const activeFilters = rootGetters['CatalogCategory/activeFilters'];

    const {data} = await this.$solarClient.get(`catalog/category/${categoryId}/filter-dividers/list`);

    let filterList = data;

    if(state.hasWidgetActive) {
      filterList = data.map(f => {
        f.position = f.position + 1;
        return f;
      });
    }

    commit('SET_INLINE_FILTER_FULL_LIST', filterList);
    commit('SET_INLINE_FILTER_LIST', filterList);

    if (activeFilters.length) {
      activeFilters.forEach(af => {
        dispatch('updateInlineFilters', {filter: af, remove: true});
      })
    }
  },
  updateInlineFilters({state, commit}, payload) {
    const currentFilterList = [...state.categoryInlineFilterList];

    if (payload.remove) {
      commit('SET_INLINE_FILTER_LIST', currentFilterList.filter(f => f.attribute_code !== payload.filter.code));
      return;
    }

    const inlineFilterObject = state.categoryInlineFilterFullList.filter(f => f.attribute_code === payload.filter.code) || [];
    const updatedList = [...currentFilterList, ...inlineFilterObject].sort((a, b) => a.position - b.position);

    commit('SET_INLINE_FILTER_LIST', updatedList);
  },
  resetInlineFilters({state, commit}) {
    commit('SET_INLINE_FILTER_LIST', state.categoryInlineFilterFullList);
  }
};

const getters = {
  getCategoryInlineFilterList: state => state.categoryInlineFilterList,
};

const InlineFilters = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default InlineFilters;
