import Environment from "@flashpointbv/solar-ui-environment";

('nav>ul').dropDown(el => {
    const dropDown = el.getElementsByClassName('_dropdown');
    if (dropDown.length > 0) {
        const overlayDiv = document.getElementsByClassName('nav__overlay')[0] as HTMLElement;
        const menuId = el.getAttribute('data-dropdown');
        const dropdownContainer = document.querySelector(`[data-dropdown-content="${menuId}"]`);
        const dropdown = window.sessionStorage.getItem(`menuItem-${menuId}`);

        dropDown[0].classList.add('active');
        overlayDiv.classList.add('active');

        if (dropdown) {
            dropdownContainer.innerHTML = dropdown;
            return;
        }

        dropdownContainer.innerHTML = '<div class="--loading --small"><div class="--spinner"></div></div>';

        fetch(`/api/navigation/get-dropdown/${menuId}`, {
            headers: {
                'X-XSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')['content'] || '',
                'X-STORE-ID': Environment().get('active_store_id')
            }
        }).then(
            response => response.text()
        ).then(data => {
            window.sessionStorage.setItem(`menuItem-${menuId}`, data);
            dropdownContainer.innerHTML = data;
        });
    }
}, el => {
    const dropDown = el.getElementsByClassName('_dropdown');
    if (dropDown.length > 0) {
        const overlayDiv = document.getElementsByClassName('nav__overlay')[0] as HTMLElement;
        dropDown[0].classList.remove('active');
        overlayDiv.classList.remove('active');
    }
}, 200);

import SimpleLightbox from 'simplelightbox';

document.addEventListener('DOMContentLoaded', () => {
    let gallery = new SimpleLightbox('.lightbox__container a', {
        maxZoom: 5,
        loop: false,
        overlayOpacity: 0.7,
        navText: ['', ''],
        closeText: '',
        showCounter: false,
    });

    gallery.on('changed.simplelightbox', function (e) {
        const mediaGallery = document.getElementById('mediaGallery');
        const blazeSlider = mediaGallery['blazeSlider'];

        const thumbnailGallery = document.getElementById('thumbnailGallery');
        const blazeSliderThumbnail = thumbnailGallery['blazeSlider'];

        const index = e.target.getAttribute('data-index');

        blazeSlider.slideTo(index);
        blazeSliderThumbnail.slideTo(index);
    });
});

window.addEventListener('load', () => {
    if (window.outerWidth < 768) {
        const uspList = document.querySelectorAll('.top__usp li');
        if (uspList.length > 0) {
            (uspList[0] as HTMLElement).style.display = 'none';
            (uspList[Math.floor(Math.random() * uspList.length)] as HTMLElement).style.display = 'flex';
        }

        Array.from(document.querySelectorAll('.footer-container .footer__menu ul')).forEach(el => {
            Array.from(el.querySelectorAll('li')).forEach((childEl, i) => {
                if (i === 0) {
                    childEl.addEventListener('click', () => {
                        toggleElements(el);
                    });
                } else {
                    childEl.style.display = 'none';
                }
            });
        });
    }

    function toggleElements(currentEl) {
        if (currentEl.firstChild.classList.contains('_active')) {
            currentEl.firstChild.classList.remove('_active');
            currentEl.querySelectorAll('li').forEach((childEl, i) => {
                if (i !== 0) {
                    childEl.style.display = 'none';
                }
            });
        } else {
            Array.from(document.querySelectorAll('.footer-container .footer__menu ul')).forEach(el => {
                Array.from(el.querySelectorAll('li')).forEach((childEl, i) => {
                    childEl.classList.remove('_active');
                    if (i !== 0) {
                        childEl.style.display = 'none';
                    }
                });
            });
            Array.from((currentEl as HTMLElement).querySelectorAll('li')).forEach((childEl, i) => {
                if (i === 0) {
                    childEl.classList.add('_active');
                }
                childEl.style.display = 'block';
            });
        }
    }

    const clickableMenuItems = document.getElementsByClassName('is-drilldown-submenu-parent');
    if (clickableMenuItems.length) {
        const offCanvasMenu = document.getElementById('offCanvas');
        for (let i = 0; i < clickableMenuItems.length; i++) {
            clickableMenuItems[i].addEventListener('click', function () {
                offCanvasMenu.getElementsByClassName('modal__content')[0].scrollTop = 0;
            });
        }
    }

    pageBuilderWidgetTabs();
});

function pageBuilderWidgetTabs() {
    const sizeCharts = document.querySelectorAll('.catalog__product__size-chart');

    sizeCharts.forEach((sizeChart) => {
        const pageBuilderTabItems = sizeChart.querySelectorAll('dl.page-builder__tab-container');

        pageBuilderTabItems.forEach((tabItem, index) => {
            if (index === 0) {
                tabItem.classList.add('--active');
            }

            tabItem.getElementsByTagName('dt')[0].addEventListener('click', function () {
                if (this.parentElement.classList.contains('--active')) {
                    this.parentElement.classList.remove('--active');
                    return;
                }

                pageBuilderTabItems.forEach((tabItemEl) => {
                    tabItemEl.classList.remove('--active');
                });

                this.parentElement.classList.add('--active');
            });
        });
    });
}
