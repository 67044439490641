import Environment from '@flashpointbv/solar-ui-environment';

interface AssetHelperInterface {
  getAsset(path: string, absolute?: boolean): string;
  getImageAsset(path: string, absolute?: boolean): string;
  getStaticAsset(path: string): string;
  getStaticImageAsset(path: string): string;
}

class AssetHelper implements AssetHelperInterface {
  protected activeTheme: string;

  constructor() {
    this.activeTheme = Environment().get('active_theme_code');
  }

  public getAsset(path: string, absolute: boolean = false): string {
    return `${absolute ? '/' : ''}area/web/${this.activeTheme}/assets/${this.preparePath(path)}`;
  }

  public getImageAsset(path: string, absolute: boolean = false): string {
    return this.getAsset(`images/${path}`, absolute);
  }

  public getStaticAsset(path: string): string {
    return `/static_solar/assets/${this.preparePath(path)}`;
  }

  public getStaticImageAsset(path: string): string {
    return this.getStaticAsset(`images/${path}`);
  }

  protected preparePath(path: string): string {
    return path.replace(/\s/g, '-');
  }
}

const assetHelper = (): AssetHelperInterface => {
  return new AssetHelper();
}

export {assetHelper, AssetHelperInterface};
