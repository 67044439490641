import Vue from 'vue';
import {appLocalStorage} from '../../../../shared/services';

const initialState = (state: object): object => ({...state})

const state = initialState({
    productMediaActiveSlide: 0,
    tierPrices: null,
    tierPrice: 0,
    qty: 1,

    item_id: null,
    item_group_id: null,

    sizeChartIsLoading: false,
    sizeChart: appLocalStorage.getItem('catalog-product-size-chart', null),
    sizeChartInformation: null,
});

const getters = {
    getQty: state => state.qty,
    getTierPriceForSelectedProduct: state => state.tierPrice,
    dataLayerParams: (state: object) => state,

    getSizeChart: (state: object) => state['sizeChart'],
    getSizeChartIsLoading: (state: object) => state['sizeChartIsLoading'],
    getSizeChartInformation: (state: object) => state['sizeChartInformation'],
};

const mutations = {
    SET_QTY(state, payload) {
        state.qty = payload;
    },
    SET_TIER_PRICES(state, payload) {
        state.tierPrices = payload;
    },
    SET_TIER_PRICE(state, payload) {
        state.tierPrice = payload;
    },
    SET_DATA_LAYER_PARAM(state: object, payload: object): void {
        for (let key in payload) {
            Vue.set(state, key, payload[key]);
        }
    },
    SET_SIZE_CHART(state: object, payload: object): void {
        Vue.set(state, 'sizeChart', payload);
    },
    SET_SIZE_CHART_IS_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'sizeChartIsLoading', payload);
    },
    SET_SIZE_CHART_INFORMATION(state: object, payload: boolean): void {
        Vue.set(state, 'sizeChartInformation', payload);
    }
};

const actions = {
    async setQty({commit, dispatch}, payload): Promise<void> {
        commit('SET_QTY', payload.value);
        dispatch('checkTierPrices', payload.productId);
    },
    setTierPrices({commit}, payload): void {
        commit('SET_TIER_PRICES', payload);
    },
    checkTierPrices({state, commit}, productId: number): void {
        commit('SET_TIER_PRICE', 0);
        const tierPrices = state.tierPrices;

        if ( ! tierPrices) {
            return;
        }

        if ( ! tierPrices.hasOwnProperty(productId)) {
            return;
        }

        const tierPriceList = (tierPrices[productId] || []).map((tierPrice) => {
            return {
                qty: parseFloat(tierPrice.qty),
                price: tierPrice.price
            };
        });

        for (let i = 0; i < tierPriceList.length; i++) {
            if (state.qty >= tierPriceList[i].qty) {
                commit('SET_TIER_PRICE', tierPriceList[i].price);
            }
        }
    },
    setDataLayerParams({commit}, payload: object): void {
        commit('SET_DATA_LAYER_PARAM', payload);
    },
    async fetchSizeChart({commit}, payload: object): Promise<void> {
        if (appLocalStorage.getItem('catalog-product-size-chart', null) && ! payload['force']) {
            return;
        }

        commit('SET_SIZE_CHART_IS_LOADING', true);

        try {
            const {data} = await this.$solarClient.get(`catalog/product/${payload['productId']}/size-chart`);

            if (data) {
                commit('SET_SIZE_CHART', data);
                appLocalStorage.setItem('catalog-product-size-chart', data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            commit('SET_SIZE_CHART_IS_LOADING', false);
        }
    },
    setSizeChartInformation({commit}, payload: null | object): void {
        commit('SET_SIZE_CHART_INFORMATION', payload);
    }
};

const CatalogProductView = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default CatalogProductView;
