const initialState = () => {
  return {
    pickupPoints: [],
    selectedPickupPoint: null,
    loadingPickupPoints: true,
    customer: {zipcode: null, lat: 0, long: 0},
  }
}

const getters = {}

const mutations = {
  SET_LOADING_PICKUP_POINTS(state, status) {
    state.loadingPickupPoints = status
  },
  SET_PICKUP_POINTS(state, pickupPoints) {
    state.pickupPoints = pickupPoints
  },
  SET_PICKUP_POINT(state, pickupPoint) {
    if (pickupPoint === '') {
      state.selectedPickupPoint = null
      return
    }

    state.selectedPickupPoint = pickupPoint
  },
}

const actions = {
  async collectPickupPoints({rootState, commit}) {
    commit('SET_LOADING_PICKUP_POINTS', true)
    try {
      const {data} = await this.$solarClient.post(`checkout/dhl/pickup-points/nearest/${rootState['CheckoutCheckout']['shippingAddress']['country_id']}`, {
        address: {
          postalCode: rootState['CheckoutCheckout']['shippingAddress']['postcode'],
          city: rootState['CheckoutCheckout']['shippingAddress']['city'],
          street: rootState['CheckoutCheckout']['shippingAddress']['street'][0],
          houseNumber: rootState['CheckoutCheckout']['shippingAddress']['street'][1],
          showUnavailable: 'false',
        }
      })

      commit('SET_PICKUP_POINTS', data)
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false)
    }
  },
  setPickupPoint({commit}, pickupPoint) {
    commit('SET_PICKUP_POINT', pickupPoint)
    commit('CheckoutCheckout/ADD_EXTENSION_ATTRIBUTE', {
      dhl_pickup_point: pickupPoint['LocationCode'],
    }, {root: true})
  },
  removePickupPoints({commit}) {
    commit('SET_PICKUP_POINT', null)
    commit('CheckoutCheckout/REMOVE_EXTENSION_ATTRIBUTE', 'dhl_pickup_point', {root: true})
  },
  resetPickupPoints({commit, dispatch}) {
    commit('SET_PICKUP_POINTS', [])
    commit('SET_PICKUP_POINT', null)
    commit('SET_LOADING_PICKUP_POINTS', true)
  },
  async collectPickupPointsForZipcode({ commit, rootState }, postalCode: string): Promise<void> {
    commit('SET_LOADING_PICKUP_POINTS', true)

    try {
      const countryId = rootState['CheckoutCheckout']['shippingAddress']['country_id']
      const {data} = await this.$solarClient.post(`checkout/dhl/pickup-points/nearest/${countryId}`, {
        address: {
          postalCode: postalCode,
          showUnavailable: 'false',
        }
      })

      commit('SET_PICKUP_POINTS', data)
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false)
    }
  }
}

const state = initialState()

const CheckoutPostNLPickupPoints = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutPostNLPickupPoints
