import Vue from "vue";

const initialState = () => {
    return {
        checkSingleProductState: false,
        selectedOptions: [],
        prices: {
            priceInclTax: 0,
            priceExclTax: 0,
            totalPriceInclTax: 0,
            totalPriceExclTax: 0
        },
        step: 0,

        relationProducts: [],
        relationSizeHover: {
            size: null,
            option_id: null,
            active: false,
        },
        relationSizeClick: {
            size: null,
            option_id: null,
            active: false,
        },

        sizeChartAnimation: false,
    }
};

const state = initialState();

const mutations = {
    SET_SELECTED_OPTION(state, payload) {
        state.selectedOptions.push(payload);
    },
    UPDATE_SELECTED_OPTION(state, {payload, selectedOptionIndex}) {
        state.selectedOptions[selectedOptionIndex] = payload;
    },
    UPDATE_SELECTED_ATTRIBUTES(state, startIndex) {
        state.selectedOptions.splice(startIndex);
    },
    UPDATE_CURRENT_STEP(state, currentStep) {
        state.step = currentStep;
    },
    SET_SINGLE_PRODUCT_STATE(state, isSingleProduct) {
        state.checkSingleProductState = isSingleProduct;
    },
    SET_PRICES(state, product) {
        state.prices = {
            priceInclTax: product.price_incl_tax,
            priceExclTax: product.price_excl_tax,
            totalPriceInclTax: product.final_price_incl_tax,
            totalPriceExclTax: product.final_price_excl_tax
        };
    },
    SET_RELATION_PRODUCTS(state, products) {
        Vue.set(state, 'relationProducts', products);
    },
    SET_RELATION_SIZE_HOVER(state, payload) {
        const stateToMutate = payload.eventType === 'click' ? 'relationSizeClick' : 'relationSizeHover';

        Vue.set(state[stateToMutate], 'size', payload.size);
        Vue.set(state[stateToMutate], 'option_id', payload.option_id);
        Vue.set(state[stateToMutate], 'active', payload.active);
    },
    SET_SIZE_CHART_ANIMATION(state, payload) {
        Vue.set(state, 'sizeChartAnimation', payload);
    },
};

const actions = {
    setSelectedOption({state, commit, dispatch, getters}, payload) {
        const attributeIndex = getters.availableAttributes.findIndex(o => o.code === payload.attributeCode);
        const selectedOptionIndex = state.selectedOptions.findIndex(o => o.attributeCode === payload.attributeCode);

        if (selectedOptionIndex === -1) {
            commit('SET_SELECTED_OPTION', payload);
        } else {
            commit('UPDATE_SELECTED_OPTION', {payload, selectedOptionIndex});
        }

        if ((attributeIndex + 1) <= getters.availableAttributes.length) {
            commit('UPDATE_SELECTED_ATTRIBUTES', attributeIndex + 1);
        }

        //If we are in the final step we update the price if necessary
        if (getters.selectedProducts.length === 1) {
            commit('SET_PRICES', getters.selectedProducts[0]);
        }
    },
    updateStep({state, commit}, currentStep) {
        commit('UPDATE_CURRENT_STEP', currentStep);
    },
    getAttributeOptionsByCode({state, rootState, commit, dispatch, getters}, attributeCode) {
        const productsBySelectedAttributes = getters.selectedProducts;

        // If there is only one product available
        if (productsBySelectedAttributes.length === 1) {
            commit('SET_SINGLE_PRODUCT_STATE', true);
        }

        //Set the base price
        commit('SET_PRICES', rootState.CatalogProduct.productData);

        return productsBySelectedAttributes.reduce((result, currentValue) => {
            (result[currentValue[attributeCode]] = result[currentValue[attributeCode]] || []).push(currentValue);
            return result;
        }, {});
    },
    initProductRelations({commit}, products) {
        commit('SET_RELATION_PRODUCTS', products);
    },
    setRelationSizeHover({commit}, payload) {
        commit('SET_RELATION_SIZE_HOVER', payload);
    },
    setSizeChartAnimation({commit}, payload) {
        commit('SET_SIZE_CHART_ANIMATION', payload);

        setTimeout(() => {
            commit('SET_SIZE_CHART_ANIMATION', false);
        }, 500);
    },
};

const getters = {
    virtualProducts: (state, getters, rootState) => {
        return rootState.CatalogProduct.productData['virtual_products'] || [];
    },
    availableAttributes: (state, getters, rootState) => {
        return rootState.CatalogProduct.productData['configurable_data'] || [];
    },
    currentStep: state => {
        return state.step;
    },
    selectedOptions: state => {
        return state.selectedOptions;
    },
    checkSingleProduct: state => {
        return state.checkSingleProductState;
    },
    isSaleable: (state, getters) => {
        return getters.currentStep === getters.availableAttributes.length;
    },
    totalPrices: state => {
        return state.prices;
    },
    selectedProducts: (state, getters) => {
        return getters.virtualProducts.filter(p => {
            return state.selectedOptions.every(o => p[`${o.attributeCode}_option_id`] === parseInt(o.selectedValue)) && parseInt(p['status']) === 1;
        });
    },
    relationProducts: state => state.relationProducts,
    relationSizeHover: state => state.relationSizeHover,
    relationSizeClick: state => state.relationSizeClick,
    sizeChartAnimation: state => state.sizeChartAnimation,
};

const CatalogConfigurableProduct = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default CatalogConfigurableProduct;
