export default function highlight(value: string, search: string): string {
    if ( ! search) {
        return value;
    }

    return value.toString().replace(new RegExp(search, 'gi'), (match: string): string => {
        return `<span class="--highlight">${match}</span>`;
    });
}

String.prototype['highlight'] = function (search: string): string {
    return highlight(this, search);
}
