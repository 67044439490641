import {HttpClientFactory} from "@flashpointbv/solar-ui-http-client"
import Environment from "@flashpointbv/solar-ui-environment"

export default HttpClientFactory.makeInstance({
  baseURL: Environment().get('solar_api_base_url'),
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-XSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')['content'] || '', // TODO: This is deprecated since this API is stateless
      'X-STORE-ID': Environment().get('active_store_id')
    },
  },
  withCredentials: true,
  timeout: (1000 * 60)
})
