
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'header-cart'
})

export default class extends Vue {
  @Prop({default: () => 0}) private initialCount: number

  private get quoteItemCount(): number {
    return this.$store.state.CheckoutGlobal.quoteItemCount > 0 ? this.$store.state.CheckoutGlobal.quoteItemCount : this.initialCount
  }
}
