import {createAddressFromSource} from "../../../../helpers/customer/address";

const initialState: CallableFunction = (): object => {
  return {
    loadingShippingMethods: true,
    shippingMethodCode: null,
    shippingMethods: []
  }
}

const getters = {
  shippingMethod: (state) => state.shippingMethods.find((s: object): boolean => `${s['carrier_code']}_${s['method_code']}` === state.shippingMethodCode) || null,
  shippingMethodMethodCode: (state, getters, rootState) => getters.shippingMethod ? getters.shippingMethod['method_code'] : rootState.CheckoutCart.defaultMethodCode,
  shippingMethodCarrierCode: (state, getters, rootState) => getters.shippingMethod ? getters.shippingMethod['carrier_code'] : rootState.CheckoutCart.defaultCarrierCode,
}

const mutations = {
  SET_LOADING_SHIPPING_METHODS(state, status): void {
    state.loadingShippingMethods = status
  },
  SET_SHIPPING_METHOD_CODE(state, code: string): void {
    state.shippingMethodCode = code
  },
  SET_SHIPPING_METHODS(state, methods: object[]): void {
    state.shippingMethods = methods
  }
}

const actions = {
  async collectShippingMethods({state, commit}) {
    commit('SET_LOADING_SHIPPING_METHODS', true)
    try {
      const {data} = await this.$solarStatefulClient.get(`/checkout/cart/shipping-methods`)

      if (null === state.shippingMethodCode && data.length > 0) {
        const newShippingMethod: object = data[0]
        const newShippingMethodCode: string = `${newShippingMethod['carrier_code']}_${newShippingMethod['method_code']}`
        commit('SET_SHIPPING_METHOD_CODE', newShippingMethodCode)
      }

      commit('SET_SHIPPING_METHODS', data)
    } catch (e) {
      console.error(e)
    } finally {
      commit('SET_LOADING_SHIPPING_METHODS', false)
    }
  },
  async estimateShippingMethods({commit}, address: object): Promise<void> {
    commit('SET_LOADING_SHIPPING_METHODS', true)
    const {data: shippingMethods} = await this.$solarStatefulClient.post('checkout/cart/estimate-shipping-methods', {address: createAddressFromSource(address)})
    commit('SET_SHIPPING_METHODS', shippingMethods)
    commit('SET_LOADING_SHIPPING_METHODS', false)
  },
  async setFirstAvailableShippingMethod({state, commit}): Promise<void> {
    if (state.shippingMethods.length < 1) {
      return
    }

    const newShippingMethod: object = state.shippingMethods[0]
    const newShippingMethodCode: string = `${newShippingMethod['carrier_code']}_${newShippingMethod['method_code']}`

    commit('SET_SHIPPING_METHOD_CODE', newShippingMethodCode)
  }
}

const state: object = initialState()

const CheckoutShipping: object = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutShipping
