
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'catalog-product-list-count'
})

export default class extends Vue {
  @Prop({required: true}) readonly dataSource: string;
  @Prop({default: null}) readonly count: null | number;

  private get pageProductCount(): number {
    return this.$store.getters[`${this.dataSource}/totalItemCount`] > 0 ? this.$store.getters[`${this.dataSource}/totalItemCount`] : this.count;
  }
}
