export class SolarEmailSimilarity {
  static instance: SolarEmailSimilarity;

  static config: object = {
    'message': 'Did you mean {email}?',
    'messageClass': 'similarity-message',
    'debounceTime': 750,
  };

  constructor(config?: object) {
    const defaultConfig = {
      ...SolarEmailSimilarity.config,
      ...config
    };

    const emailFields = document.querySelectorAll('[email-similarity-check]');

    emailFields.forEach((emailField) => {
      const similarityMessage = document.createElement('div');
      similarityMessage.classList.add(defaultConfig['messageClass']);

      const onType = this.#debounce(async function () {
        const data = await fetch('/api/email-similarity/check-email?' + new URLSearchParams({
          email: (emailField as HTMLInputElement).value
        })).then(response => response.json());

        if (data.length) {
          similarityMessage.innerHTML = defaultConfig['message'].replace('{email}', `<span>${data[0]}</span>`);
          emailField.after(similarityMessage);

          similarityMessage.getElementsByTagName('span')[0].addEventListener('click', function () {
            (emailField as HTMLInputElement).value = data[0];
            (emailField as HTMLInputElement).dispatchEvent(new Event('input'));
            similarityMessage.remove();
          });
        } else {
          similarityMessage.remove();
        }
      }, defaultConfig['debounceTime']);

      emailField.addEventListener('keydown', onType);
    });
  }

  #debounce(func, wait) {
    let timeout;
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(func, wait);
    }
  }
}
