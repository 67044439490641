
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'search-form',
  components: {
    AutocompleteCategoryResults: () => import('./search/AutocompleteCategories.vue'),
    AutocompleteProductResults: () => import('./search/AutocompleteProducts.vue'),
    AutocompleteFilterResults: () => import('./search/AutocompleteFilters.vue'),
    AutocompleteKeywordResults: () => import('./search/AutocompleteKeywords.vue'),
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event: Event) => {
          if ( ! (el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](false);
          }
        };
        document.body.addEventListener('click', el['clickOutsideEvent']);
      },
      unbind(el) {
        document.body.removeEventListener('click', el['clickOutsideEvent']);
      }
    }
  }
})

export default class extends Vue {
  $refs!: {
    autocompleteForm: HTMLFormElement,
    input: HTMLFormElement,
  }

  private inputQuery: string = '';
  private typedQuery: string = '';

  protected autocompleteData: object = {};

  private _throttle: number;
  private autocompleteInitiated: boolean = false;
  private autocompleteLoading: boolean = false;
  private autocompleteRemove: boolean = false;
  private showAutocomplete: boolean = false;
  private mobileBreakpoint: number = 768;
  private windowWidth: number = window.innerWidth;
  private showSearchButton: boolean = true;
  private debounceDelay: number = 500;

  private created(): void {
    this.typedQuery = this.inputQuery;
  }

  private async triggerAutocomplete(useThrottle: boolean = true): Promise<void> {
    const formData = new FormData(this.$refs.autocompleteForm);
    const searchQuery = formData.get('query');

    if ( ! useThrottle) {
      await this.fetchAutocompleteData(searchQuery.toString());
      return;
    }

    clearTimeout(this._throttle);
    this._throttle = window.setTimeout(async () => {

      await this.fetchAutocompleteData(searchQuery.toString());
    }, this.debounceDelay);
  }

  private async fetchAutocompleteData(searchQuery: string): Promise<void> {
    this.autocompleteRemove = searchQuery.length > 0;

    if (searchQuery.length < 3) {
      this.showSearchButton = this.windowWidth > this.mobileBreakpoint;
      this.toggleAutocompleteDropdown(false);
      this.autocompleteLoading = false;
      return;
    }

    if (searchQuery === this.inputQuery) {
      this.toggleAutocompleteDropdown(true);
      this.autocompleteInitiated = true;
      return;
    }

    try {
      this.autocompleteLoading = true;
      const {data} = await this.$solarClient.get('search/autocomplete', {
        params: {query: searchQuery}
      });

      this.autocompleteData = data;
      this.inputQuery = searchQuery;
    } catch (e) {
      console.error(e);
    } finally {
      this.autocompleteInitiated = true;
    }

    this.toggleAutocompleteDropdown(true);
    this.autocompleteLoading = false;
    this.showSearchButton = true;
  }

  private get componentOrderMap(): object {
    return {
      'keyword_results': 1,
      'category_results': 2,
      'filter_results': 3,
      'product_results': 4,
    };
  }

  private get activeResultComponents(): Array<object> {
    return Object.entries({...this.autocompleteData}).map(([key, value]) => {
      if ( ! value.hasOwnProperty('matches')) {
        return null;
      }

      return {
        component: `autocomplete-${key.replace(/_/g, '-')}`,
        order: this.componentOrderMap[key] || Object.keys(this.componentOrderMap).length + 1,
        props: {
          items: value
        }
      };
    }).filter((item) => item !== null && item['props']['items']['total_matches'] > 0).sort((a, b) => a['order'] - b['order']);
  }

  private clearSearchInput(): void {
    this.inputQuery = '';
    this.$refs.input.value = '';
    this.typedQuery = '';
    this.$refs.input.focus();
    this.autocompleteRemove = false;
    this.toggleAutocompleteDropdown(false);
  }

  private toggleAutocompleteDropdown(state: boolean = false): void {
    this.showAutocomplete = state;

    const backdropElement: HTMLElement = document.querySelector('.--backdrop');
    backdropElement.style.opacity = state ? '1' : '0';
    backdropElement.style.display = state ? 'block' : 'none';

    if (state) {
      document.body.classList.add('no-scroll');
      return;
    }

    document.body.classList.remove('no-scroll');
  }
}
