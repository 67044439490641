const initialState = (): object => {
  return {
    paymentMethodCode: null,
    paymentMethods: [],

    loadingTotalsSummary: false,
    loadingPaymentData: false,
    loadingCheckout: false,

    paymentDetails: {},
    shippingOptions: {},

    hasPaymentErrorMessage: false,
    paymentErrorMessage: null,

    couponCode: '',
    couponStatus: null,
    couponMessage: '',
    couponRequestIsLoading: false,

    extensionAttributes: {},
  }
}

const mutations: object = {
  SET_SELECTED_PAYMENT_METHOD_CODE(state, paymentMethodeCode: null | string) {
    state.paymentMethodCode = paymentMethodeCode
  },
  SET_PAYMENT_METHODS(state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },
  SET_LOADING_TOTALS_SUMMARY(state, status) {
    state.loadingTotalsSummary = status
  },
  SET_LOADING_PAYMENT_DATA(state, status) {
    state.loadingPaymentData = status
  },
  SET_LOADING_CHECKOUT(state, status) {
    state.loadingCheckout = status
  },
  ADD_PAYMENT_DETAILS(state, detail) {
    state.paymentDetails = {...state.paymentDetails, ...detail}
  },
  CLEAR_PAYMENT_DETAILS(state) {
    state.paymentDetails = {}
  },
  ADD_SHIPPING_OPTION(state, detail) {
    state.shippingOptions = {...state.shippingOptions, ...detail}
  },
  CLEAR_SHIPPING_OPTION(state) {
    state.shippingOptions = {}
  },
  SET_COUPON_CODE(state, couponCode: string) {
    state.couponCode = couponCode
  },
  SET_COUPON_STATUS(state, status) {
    state.couponStatus = status
  },
  SET_COUPON_MESSAGE(state, message) {
    state.couponMessage = message
  },
  SET_COUPON_REQUEST_LOADING(state, status) {
    state.couponRequestIsLoading = status
  },
  SET_ERROR_MESSAGE(state, message) {
    state.hasPaymentErrorMessage = true
    state.paymentErrorMessage = message
  },
  ADD_EXTENSION_ATTRIBUTE(state, extensionAttribute: object) {
    state.extensionAttributes = {...state.extensionAttributes, ...extensionAttribute}
  },
  REMOVE_EXTENSION_ATTRIBUTE(state, extensionAttribute: string) {
    if (state.extensionAttributes.hasOwnProperty(extensionAttribute)) {
      delete state.extensionAttributes[extensionAttribute]
    }
  }
}

const actions: object = {
  async setPaymentMethod({rootGetters, commit}, paymentMethod: object): Promise<void> {
    commit('SET_SELECTED_PAYMENT_METHOD_CODE', paymentMethod['code'])
    commit('SET_LOADING_PAYMENT_DATA', true)

    const additionalData: object = {}

    if (paymentMethod['code'].includes('buckaroo')) {
      additionalData['buckaroo_skip_validation'] = true
    }

    const [
      _billingAddress,
      _shippingAddress,
    ] = rootGetters['CheckoutCheckout/getAddressData']

    let payload = {
      paymentMethod: {method: paymentMethod['code'], additional_data: additionalData},
    }

    if ( ! rootGetters["CheckoutGlobal/isLoggedIn"]) {
      payload['email'] = rootGetters["CheckoutGlobal/getCustomerEmail"]
    }

    try {
      await this.$solarStatefulClient.post(`/checkout/cart/set-payment-information`, payload)
    } catch (error) {
      console.error(error)
      return
    }

    commit('SET_LOADING_PAYMENT_DATA', false)
  },
  async collectPaymentMethods({rootState, rootGetters, commit, dispatch}): Promise<void> {
    commit('SET_LOADING_PAYMENT_DATA', true)
    commit('CheckoutTotals/SET_LOADING_TOTALS', true, {root: true})

    const shippingMethodMethodCode = rootGetters['CheckoutShipping/shippingMethodMethodCode']
    const shippingMethodCarrierCode = rootGetters['CheckoutShipping/shippingMethodCarrierCode']

    const [_billingAddress, _shippingAddress] = rootGetters['CheckoutCheckout/getAddressData']
    let _extensionAttributes = {...rootState['CheckoutCheckout']['extensionAttributes']}

    delete _billingAddress['id']
    delete _shippingAddress['id']

    if (_extensionAttributes.hasOwnProperty('requested_delivery_date')) {
      _extensionAttributes['requested_delivery_date'] = _extensionAttributes['requested_delivery_date'] ? JSON.stringify(_extensionAttributes['requested_delivery_date']) : null
    }

    try {
      const {data} = await this.$solarStatefulClient.post(`/checkout/cart/shipping-information`, {
        addressInformation: {
          shippingAddress: _shippingAddress,
          billingAddress: _billingAddress,
          shippingMethodCode: shippingMethodMethodCode,
          shippingCarrierCode: shippingMethodCarrierCode,
          extensionAttributes: _extensionAttributes,
        }
      })

      const paymentMethods = data['payment_methods'] || []

      commit('CheckoutTotals/SET_TOTALS', data['totals'], {root: true})
      commit('SET_PAYMENT_METHODS', paymentMethods)
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADING_PAYMENT_DATA', false)
    }
  },
  async getCouponCode({commit}): Promise<boolean> {
    try {
      const {data} = await this.$solarStatefulClient.get(`/checkout/cart/coupons`)

      if (data.length) {
        commit('SET_COUPON_CODE', data)
        commit('SET_COUPON_STATUS', true)
        return true
      }

      return false
    } catch (e) {
      return false
    }
  },
  async applyCouponCode({commit}, payload): Promise<void> {
    const couponCode = payload.hasOwnProperty('couponCode') ? payload.couponCode : payload

    commit('SET_COUPON_REQUEST_LOADING', true)
    commit('CheckoutTotals/SET_LOADING_TOTALS', true, {root: true})

    if (!couponCode.length) {
      commit('SET_COUPON_STATUS', false)
      commit('SET_COUPON_REQUEST_LOADING', false)
      commit('SET_COUPON_MESSAGE', 'Please enter a valid code')
      commit('CheckoutTotals/SET_LOADING_TOTALS', false, {root: true})
      return
    }

    try {
      const {data} = await this.$solarStatefulClient.post(`/checkout/cart/coupons`, {
        coupon_code: couponCode
      })

      if (data) {
        commit('SET_COUPON_CODE', couponCode)
        commit('SET_COUPON_STATUS', true)
      }
    } catch ({response}) {
      commit('SET_COUPON_MESSAGE', response.data.message)
      commit('SET_COUPON_STATUS', false)
    } finally {
      commit('CheckoutTotals/SET_LOADING_TOTALS', false, {root: true})
      commit('SET_COUPON_REQUEST_LOADING', false)
    }
  },
  async removeCouponCode({commit}): Promise<void> {
    commit('SET_COUPON_REQUEST_LOADING', true)
    commit('CheckoutTotals/SET_LOADING_TOTALS', true, {root: true})
    try {
      await this.$solarStatefulClient.delete(`/checkout/cart/coupons`)
      commit('SET_COUPON_CODE', '')
      commit('SET_COUPON_STATUS', null)
    } catch (e) {
      commit('SET_COUPON_MESSAGE', 'The coupon code could not be cancelled, please try again later.')
    } finally {
      commit('CheckoutTotals/SET_LOADING_TOTALS', false, {root: true})
      commit('SET_COUPON_REQUEST_LOADING', false)
    }
  },
  async placeOrder({state, getters, rootGetters, commit}): Promise<void> {
    commit('SET_LOADING_CHECKOUT', true)
    const [_billingAddress, _shippingAddress] = rootGetters['CheckoutCheckout/getAddressData']
    const _extensionAttributes = state.extensionAttributes
    try {
      return await this.$solarStatefulClient.post('/checkout/cart/payment-information', {
        email: rootGetters['CheckoutGlobal/getCustomerEmail'],
        shippingOptions: state.shippingOptions,
        paymentMethod: {
          method: getters.paymentMethod['code'],
          additional_data: state.paymentDetails,
          extensionAttributes: _extensionAttributes
        },
        billingAddress: _billingAddress
      })
    } catch (e) {
      const {message} = e.response.data
      commit('SET_ERROR_MESSAGE', message)
      window.scrollTo(0, 0)
    } finally {
      commit('SET_LOADING_CHECKOUT', false)
    }
  }
}

const getters = {
  paymentMethod: (state): null | object => {
    if (null === state.paymentMethodCode) {
      return null
    }

    return state.paymentMethods.find(p => p['code'] === state.paymentMethodCode)
  }
}

const state = initialState()

const CheckoutPayment: object = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}

export default CheckoutPayment
