const initialState: CallableFunction = (): object => {
  return {
    totals: null,
    totalsAvailable: false,
  }
}

const mutations: object = {
  SET_TOTALS(state, totals): void {
    state.totals = totals
    state.totalsAvailable = null !== state.totals
  },
  SET_LOADING_TOTALS(state, data): void {
    state.totalsAvailable = !data
  }
}

const state: object = initialState()

const CheckoutTotals: object = {
  namespaced: true,
  state,
  mutations
}

export default CheckoutTotals
