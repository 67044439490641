interface GeneralHelperInterface {
  replaceSizeText(size: string): string;
}

class GeneralHelper implements GeneralHelperInterface {
  public replaceSizeText(size: string): string {
    const searchChunks = ['1/2', '2/3', '1/3', '1/4', '3/4'];
    const replaceChunks = ['½', '⅔', '⅓', '¼', '¾'];

    searchChunks.forEach((searchChunk: string, index: number) => {
      size = size.replace(searchChunk, replaceChunks[index]);
    });

    return size;
  }
}

const generalHelper = (): GeneralHelperInterface => {
  return new GeneralHelper();
}

export {generalHelper, GeneralHelperInterface};
