import Environment from '@flashpointbv/solar-ui-environment';

export interface CdnHelperInterface {
  convert(source: string): string;
  resize(source: string, size: number, path: string): string;
}

class CdnHelper implements CdnHelperInterface {
  protected cdnBaseUrl: string;

  constructor() {
    this.cdnBaseUrl = this.removeTrailingSlashes(
      Environment().get('solar_cdn_base_url')
    );
  }

  public resize(source: string, size: number, path: string = ''): string {
    return (source || false) ? `${this.cdnBaseUrl}/${this.removeDoubleSlashes(`${path}/${size}/${size}/${source}`)}` : '';
  }

  public convert(source: string): string {
    return (source || false) ? `${this.cdnBaseUrl}/convert/${this.removeLeadingSlashes(source)}` : '';
  }

  private removeDoubleSlashes(path: string): string {
    return path.replace(/\/{2,}/g, '/');
  }

  private removeTrailingSlashes(path: string): string {
    return path.replace(/\/+$/, '');
  }

  private removeLeadingSlashes(path: string): string {
    return path.replace(/^\/+/, '');
  }
}

export const cdnHelper = new CdnHelper();

export default cdnHelper;
