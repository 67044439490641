
  import {Component, Prop, Vue} from 'vue-property-decorator';

  @Component({
    name: 'header-wishlist'
  })

  export default class extends Vue {
    @Prop({default: [], required: false}) private items: Array<number>;

    private get wishlistCount(): number {
      return this.$store.getters['CustomerWishlist/wishlistCount'];
    }

    private created(): void {
      this.$store.dispatch('CustomerWishlist/setWishlistItems', this.items);
      this.$store.dispatch('CustomerWishlist/setWishlistCount', this.items.length);
    }
  }
