import Vue from 'vue'
import {createAddressFromSource, emptyAddressByCountryId, CustomerBillingAddress, CustomerShippingAddress, Address} from "../../../helpers/customer/address";
import {flattenRegionForAddress} from "../../../helpers/checkout/address";
import Environment from "@flashpointbv/solar-ui-environment";

const initialState = () => {
  return {
    config: {},
    currentStep: 1,
    shippingIsSameAsBilling: true,
    billingAddress: {},
    shippingAddress: {},
    extensionAttributes: {}
  }
}

const getters = {
  hasBillingAddress: (state, getters): boolean => Object.keys(getters.billingAddress).length > 0,
  hasShippingAddress: (state, getters): boolean => Object.keys(getters.shippingAddress).length > 0,
  billingAddress: (state): CustomerBillingAddress => state.shippingIsSameAsBilling ? {...state.shippingAddress, same_as_billing: 0} : state.billingAddress,
  shippingAddress: (state): CustomerShippingAddress => state.shippingAddress,
  getAddressData: (state, getters, rootState, rootGetter) => {
    let [billingAddress, shippingAddress] =  rootGetter['CheckoutGlobal/isLoggedIn'] ? getters.convertAddressData : [getters.billingAddress, getters.shippingAddress]

    if (Object.keys(billingAddress).length < 1) {
      billingAddress = emptyAddressByCountryId(Environment().get('default_country_code'))
    }

    if (Object.keys(shippingAddress).length < 1) {
      shippingAddress = emptyAddressByCountryId(Environment().get('default_country_code'))
    }

    return [billingAddress, shippingAddress]
  },
  customerAddressInformation: (state, getters) => {
    const [_billingAddress, _shippingAddress] = getters.convertAddressData
    return {billingInformation: _billingAddress, shippingInformation: _shippingAddress}
  },
  convertAddressData: (state): [CustomerShippingAddress, CustomerBillingAddress] => {
    const shippingAddress: CustomerShippingAddress = createAddressFromSource({
      ...state.shippingAddress,
    })

    const billingAddress: CustomerBillingAddress = createAddressFromSource({
      ...state.billingAddress,
    })

    if (state.shippingIsSameAsBilling) {
      billingAddress['save_in_address_book'] = null
    }

    return [billingAddress, shippingAddress]
  },
  getRequestedDeliveryDate: state => state.extensionAttributes['requested_delivery_date'] || null,
}

const mutations = {
  SET_CONFIG(state, config: object) {
    state.config = config
  },
  SET_SHIPPING_IS_SAME_AS_BILLING(state, status) {
    state.shippingIsSameAsBilling = status
  },
  SET_CURRENT_STEP(state, step) {
    state.currentStep = step
  },
  SET_CUSTOMER_EMAIL(state, email: string) {
    state.shippingAddress['email'] = email
    state.billingAddress['email'] = email
  },
  SET_BILLING_ADDRESS(state, address) {
    state.billingAddress = {...address, email: address.email || state.billingAddress.email || null}
  },
  SET_SHIPPING_ADDRESS(state, address) {
    state.shippingAddress = {...address, email: address.email || state.shippingAddress.email || null}
  },
  ADD_EXTENSION_ATTRIBUTE(state, extensionAttribute) {
    state.extensionAttributes = {...state.extensionAttributes, ...extensionAttribute}
  },
  REMOVE_EXTENSION_ATTRIBUTE(state, extensionAttribute) {
    if (state.extensionAttributes.hasOwnProperty(extensionAttribute)) {
      delete state.extensionAttributes[extensionAttribute]
    }
  },
  SET_REQUESTED_DELIVERY_DATE(state, requestedDeliveryDate) {
    Vue.set(state.extensionAttributes, 'requested_delivery_date', requestedDeliveryDate)
  },
}

const actions = {
  gotoStep({state, commit, dispatch}, step: number): void {
    if (step === state.currentStep) {
      return
    }

    if (step - state.currentStep > 1) {
      commit('SET_CURRENT_STEP', step - 1)
    } else {
      commit('SET_CURRENT_STEP', step)
    }

    dispatch('CheckoutGlobal/resetErrors', null, { root: true })
  },
  async checkCustomerEmail({}, customerEmail: string): Promise<boolean> {
    try {
      return await this.$solarClient.post(`checkout/customers/email-available`, {
        customerEmail: customerEmail,
      })
    } catch (e) {
      return false
    }
  },
  async setBillingAddressOnQuote({commit}, address: object): Promise<boolean> {
    delete address['id']
    delete address['default_billing']

    try {
      const {data: quote} = await this.$solarStatefulClient.put('checkout/cart/address/billing-address', {address})

      commit('SET_BILLING_ADDRESS', quote['billing_address'])

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setShippingAddressOnQuote({commit}, address: object): Promise<boolean> {
    delete address['id']
    delete address['default_shipping']

    try {
      const {data: quote} = await this.$solarStatefulClient.put('checkout/cart/address/shipping-address', {address})

      commit('SET_SHIPPING_ADDRESS', quote['extension_attributes']['shipping_assignments'][0]['shipping']['address'])

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async setAddressesOnQuote({commit, dispatch}, addresses: object): Promise<boolean> {
    const _addresses: object = {...addresses}
    delete _addresses['billing_address']['id']
    delete _addresses['billing_address']['default_shipping']
    delete _addresses['shipping_address']['id']
    delete _addresses['shipping_address']['default_shipping']

    try {
      const {data: quote} = await this.$solarStatefulClient.put('checkout/cart/address/set-addresses', _addresses)

      commit('SET_BILLING_ADDRESS', quote['billing_address'])
      commit('SET_SHIPPING_ADDRESS', quote['extension_attributes']['shipping_assignments'][0]['shipping']['address'])

      return true
    } catch (e) {
      const errorMessage = e.response.data.error || this.$t('Something went wrong, please try again later') as string
      dispatch('CheckoutGlobal/addError', { type: 'critical', message: errorMessage }, { root: true })
      return false
    }
  },
  setAddressByType({dispatch}, payload: {type: string, address: Address}): void {
    const dispatchMethod: string = payload.type === 'shipping' ? 'setShippingAddress' : 'setBillingAddress'
    dispatch(dispatchMethod, payload.address)
  },
  setBillingAddress({commit}, address: Address): void {
    commit('SET_BILLING_ADDRESS', flattenRegionForAddress(address))
  },
  setShippingAddress({commit}, address: Address): void {
    commit('SET_SHIPPING_ADDRESS', flattenRegionForAddress(address))
  },
  toggleShippingIsSameAsBilling({state, commit}): void {
    commit('SET_SHIPPING_IS_SAME_AS_BILLING', !state.shippingIsSameAsBilling)
    commit('SET_SHIPPING_ADDRESS', {...state.shippingAddress, same_as_billing: state.shippingIsSameAsBilling ? 1 : 0})
  },
  checkAndSetDefaultTimeSlot({commit, getters, rootGetters}) {
    if (getters.getRequestedDeliveryDate) {
      return
    }

    commit('SET_REQUESTED_DELIVERY_DATE', rootGetters['CheckoutShipping/shippingMethod']['timeslots'][0])
  }
}

const state = initialState()

const CheckoutCheckout = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutCheckout
