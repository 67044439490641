import {Vue} from 'vue-property-decorator';

const initialState = (state: object): object => ({...state})

const state = initialState({
  options: {},
  activeOptions: {},
});

const mutations = {
  SET_PERSONALISATION_OPTIONS(state, payload) {
    Vue.set(state.options, payload.groupId, {
      ...state.options[payload.groupId],
      [payload.productId]: payload.options
    });
  },
  REMOVE_PERSONALISATION_OPTIONS(state, groupId) {
    Vue.delete(state.options, groupId);
    Vue.delete(state.activeOptions, groupId);
  },
  SET_PERSONALISATION_OPTIONS_ACTIVE(state, payload): void {
    if (state.activeOptions.hasOwnProperty(payload.groupId) && state.activeOptions[payload.groupId][payload.productId]) {
      if (state.activeOptions[payload.groupId][payload.productId][payload.option.id] && !payload.value) {
        Vue.delete(state.activeOptions[payload.groupId][payload.productId], payload.option.id);
        return;
      }

      Vue.set(state.activeOptions, payload.groupId, {
        ...state.activeOptions[payload.groupId],
        [payload.productId]: {
          ...state.activeOptions[payload.groupId][payload.productId],
          [payload.option.id]: {
            ...payload.option,
            value: payload.value
          }
        }
      });
      return;
    }

    Vue.set(state.activeOptions, payload.groupId, {
      ...state.activeOptions[payload.groupId],
      [payload.productId]: {
        [payload.option.id]: {
          ...payload.option,
          value: payload.value
        }
      }
    });
  },
  REMOVE_PERSONALISATION_OPTIONS_ACTIVE(state, payload): void {
    if (state.activeOptions[payload.groupId][payload.productId]) {
      payload.optionIds.forEach((id) => {
        Vue.delete(state.activeOptions[payload.groupId][payload.productId], id);
      });
    }
  },
  REMOVE_PRODUCT_FROM_PERSONALISATION_GROUP(state: object, payload: object): void {
    if (!state['activeOptions'][payload['groupId']]) {
      return;
    }

    Vue.delete(state['activeOptions'][payload['groupId']], payload['productId']);
  }
};

const actions = {
  initPersonalisationOptions({commit}, payload) {
    commit('SET_PERSONALISATION_OPTIONS', payload);
  },
  setPersonalisationOptionsActive({commit}, payload): void {
    commit('SET_PERSONALISATION_OPTIONS_ACTIVE', payload);
  },
  resetPersonalisationOptionsActive({commit,}, payload) {
    commit('REMOVE_PERSONALISATION_OPTIONS_ACTIVE', payload);
  },
  removePersonalisationOptions({commit}, groupId) {
    commit('REMOVE_PERSONALISATION_OPTIONS', groupId);
  },
  removeProductFromPersonalisationGroup({commit}, payload) {
    commit('REMOVE_PRODUCT_FROM_PERSONALISATION_GROUP', payload);
  }
};

const getters = {
  getPersonalisationOptionsActive: (state) => state.activeOptions,
  getPersonalisationPrice: (state) => {
    return Object.fromEntries(Object.entries(state.activeOptions).map(([groupId, products]) => {
      return [groupId, Object.fromEntries(Object.entries(products).map(([productId, options]) => {
        const price = Object.values(options).reduce((n, {price, options, value}) => {
          if (options !== undefined) {
            return n + (options.find(o => o.id === value)['price'] || 0);
          }

          return n + price;
        }, 0);
        return [productId, price];
      }))];
    }));
  },
  getTotalPersonalisationPrice: (state, getters) => {
    return Object.values(getters.getPersonalisationPrice).reduce((n, prices) => {
      return n + Object.values(prices).reduce((n, price) => {
        return n + price;
      }, 0);
    }, 0);
  },
  getPersonalisationPayload: (state) => {
    return Object.fromEntries(Object.entries(state.activeOptions).map(([groupId, products]) => {
      return [groupId, Object.fromEntries(Object.entries(products).map(([productId, options]) => {
        return [productId, Object.values(options).filter((option) => {
          return option['id'] > 0
        }).map(({id, value}) => {
          return {
            option_id: id,
            option_value: value
          }
        })];
      }))];
    }));
  },
};

const CatalogProductPersonalisation = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default CatalogProductPersonalisation;
