import Cookies from 'js-cookie';

const loadLocalWishlist = (): Array<object> => {
  const wishlist = Cookies.get('solar-customer-wishlist');
  const decodedWishlist: Array<object> = wishlist ? JSON.parse(wishlist) : [];
  return decodedWishlist.filter(i => null !== i['product']);
};

const initialState = () => {
  return {
    items: loadLocalWishlist(),
    isLoading: false,
    wishlistItemCount: 0
  }
};

const state = initialState();

const mutations = {
  SET_WISHLIST_ITEMS(state, payload) {
    state.items = payload;
  },
  SET_IS_LOADING(state, status) {
    state.isLoading = status;
  },
  SET_WISHLIST_COUNT(state, count) {
    state.wishlistItemCount = count;
  }
};

const actions = {
  setWishlistCount({commit}, payload) {
    commit('SET_WISHLIST_COUNT', payload);
  },
  setWishlistItems({commit}, payload) {
    commit('SET_WISHLIST_ITEMS', payload);
  },
  async toggleWishlistProduct({state, commit}, payload) {
    commit('SET_IS_LOADING', true);

    try {
      let currentItems = state['items'];

      const newItem = {
        id: payload['productId'],
        product_id: payload['productId']
      };

      await this.$solarClient.put('customer/wishlist/items/toggle', {
        productId: newItem['product_id']
      });

      if ( ! payload['newState']) {
        const itemIndex = currentItems.findIndex(i => i['product_id'] === newItem['product_id']);
        if (itemIndex >= 0) currentItems.splice(itemIndex, 1);
      } else {
        currentItems.push(newItem);
      }

      commit('SET_WISHLIST_ITEMS', currentItems)
      commit('SET_WISHLIST_COUNT', currentItems.length);
    } catch (e) {
      console.error(e);
    }

    commit('SET_IS_LOADING', false);
  }
};

const getters = {
  wishlistItems: state => {
    return state.items;
  },
  wishlistCount: state => {
    return state.wishlistItemCount;
  }
}

const CustomerWishlist = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CustomerWishlist;
