import Vue from 'vue';

const initialState = () => {
    return {
        activeIndex: 0,
        maxPlayers: 30,
        teamWearPlayers: {},
        teamWearPlayersPayload: {},
        teamWearPlayersIsOpen: {},

        addToCartLoading: false,
        selectedPlayerProducts: {},

        loadingProductData: false,
        productSpecifications: {},
        productSizeChart: {},
    }
};

const state = initialState();

const mutations = {
    ADD_TEAM_WEAR_PLAYER(state: object, payload: object): void {
        Vue.set(state['teamWearPlayers'], payload['playerId'], payload['bundleProducts']);
        Vue.set(state['teamWearPlayersPayload'], payload['playerId'], true);
        Vue.set(state['teamWearPlayersIsOpen'], payload['playerId'], true);
    },
    REMOVE_PLAYER_FROM_PAYLOAD(state: object, payload: number): void {
        Vue.set(state['teamWearPlayersPayload'], payload, false);
    },
    SET_TEAM_WEAR_PLAYERS_IS_OPEN(state: object, payload: object): void {
        Vue.set(state['teamWearPlayersIsOpen'], payload['playerId'], payload['isOpen']);
    },
    SET_ADD_TO_CART_LOADING(state: object, payload: boolean): void {
        Vue.set(state, 'addToCartLoading', payload);
    },
    SET_ACTIVE_INDEX(state: object, payload: number): void {
        Vue.set(state, 'activeIndex', payload);
    },
    SET_SELECT_PLAYER_PRODUCTS(state: object, payload: object): void {
        if (state['selectedPlayerProducts'][payload['bundleId']]) {
            Vue.set(state['selectedPlayerProducts'][payload['bundleId']], payload['productId'], payload['productInfo']);
            return;
        }

        Vue.set(state['selectedPlayerProducts'], payload['bundleId'], {
            [payload['productId']]: payload['productInfo']
        });
    },
    REMOVE_SELECT_PLAYER_PRODUCTS(state: object, payload: object): void {
        if ( ! state['selectedPlayerProducts'][payload['bundleId']]) {
            return;
        }

        Vue.delete(state['selectedPlayerProducts'][payload['bundleId']], payload['productId']);
    },
    REMOVE_PLAYER(state: object, payload: number): void {
        Vue.delete(state['teamWearPlayers'], payload);
        Vue.delete(state['teamWearPlayersPayload'], payload);

    },
    SET_PRODUCT_SPECIFICATIONS(state: object, payload: object): void {
        Vue.set(state['productSpecifications'], payload['productId'], payload['specifications']);
    },
    SET_PRODUCT_SIZE_CHART(state: object, payload: object): void {
        Vue.set(state['productSizeChart'], payload['productId'], payload['sizeChart']);
    },
    SET_LOADING_PRODUCT_DATA(state: object, payload: boolean): void {
        Vue.set(state, 'loadingProductData', payload);
    }
};

const actions = {
    addTeamWearPlayer({commit}, payload: Array<object>): void {
        commit('ADD_TEAM_WEAR_PLAYER', payload);
    },
    setActiveIndex({commit}, payload: number): void {
        commit('SET_ACTIVE_INDEX', payload);
    },
    setAddToCartLoading({commit}, payload: boolean): void {
        commit('SET_ADD_TO_CART_LOADING', payload);
    },
    removePlayerFromPayload({commit}, payload: number): void {
        commit('REMOVE_PLAYER_FROM_PAYLOAD', payload);
    },
    setTeamWearPlayersIsOpen({commit}, payload: object): void {
        commit('SET_TEAM_WEAR_PLAYERS_IS_OPEN', payload);
    },
    setSelectPlayerProducts({commit}, payload: object): void {
        commit('SET_SELECT_PLAYER_PRODUCTS', payload);
    },
    removeSelectPlayerProducts({commit}, payload: object): void {
        commit('REMOVE_SELECT_PLAYER_PRODUCTS', payload);
    },
    removePlayer({commit}, payload: number): void {
        commit('REMOVE_PLAYER', payload);
    },
    async collectProductData({commit}, productId: number): Promise<void> {
        commit('SET_LOADING_PRODUCT_DATA', true);

        const response = await Promise.all([
            await this.$solarClient.get(`catalog/product/${productId}/specification`),
            await this.$solarClient.get(`catalog/product/${productId}/size-chart`),
        ]);

        const [productData, sizeChart] = response;

        commit('SET_PRODUCT_SPECIFICATIONS', {
            productId,
            specifications: productData.data,
        });

        commit('SET_PRODUCT_SIZE_CHART', {
            productId,
            sizeChart: sizeChart.data,
        });

        commit('SET_LOADING_PRODUCT_DATA', false);
    }
};

const getters = {
    getMaxPlayers: state => state.maxPlayers,
    getTeamWearPlayers: state => state.teamWearPlayers,
    getTeamWearPlayersPayload: state => state.teamWearPlayersPayload,
    getPlayerCount: state => Object.keys(state.teamWearPlayers).length,
    getSelectedPlayerProducts: state => state.selectedPlayerProducts,
    getAddToCartLoading: state => state.addToCartLoading,
    getActiveIndex: state => state.activeIndex,
    getTeamWearPlayersIsOpen: state => state.teamWearPlayersIsOpen,

    getProductSpecifications: state => state.productSpecifications,
    getProductSizeChart: state => state.productSizeChart,
    getLoadingProductData: state => state.loadingProductData,
};

const CatalogProductTeamWearView = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default CatalogProductTeamWearView;
