const initialState = () => {
  return {
    hasError: false,
    errorMessage: null,
    freeArticles: {},
  }
}

const mutations = {
  SET_ADD_ARTICLE_HAS_ERROR(state, hasError) {
    state.hasError = hasError
  },
  SET_ADD_ARTICLE_ERROR_MESSAGE(state, message) {
    state.errorMessage = message
  },
  SET_FREE_ARTICLES(state, freeArticles) {
    state.freeArticles = freeArticles
  },
}

const actions = {
  async collectFreeArticlesForQuote({commit, rootState}): Promise<void> {
    if (rootState.CheckoutGlobal.quoteItemCount < 1) {
      commit('SET_FREE_ARTICLES', {})
      return
    }

    try {
      const {data} = await this.$solarStatefulClient.get(
        `/checkout/cart/gifts`
      )

      commit('SET_FREE_ARTICLES', data[0] || {})
    } catch (err) {
      console.error(err)
      commit('SET_FREE_ARTICLES', {})
    }
  },
  async addFreeArticleToQuote({commit, dispatch}, payload: object[]): Promise<object> {
    commit('SET_ADD_ARTICLE_HAS_ERROR', false)
    commit('SET_ADD_ARTICLE_ERROR_MESSAGE', null)

    try {
      const {data} = await this.$solarStatefulClient.post(`/checkout/cart/gifts/add`, {
        giftItem: {...payload}
      })

      if ( ! data.hasOwnProperty('status') || data['status'] !== 'success') {
        commit('SET_ADD_ARTICLE_HAS_ERROR', true)
        commit('SET_ADD_ARTICLE_ERROR_MESSAGE', data['message'] || 'Something went wrong, please try again later')
        return
      }
    } catch (err) {
      commit('SET_ADD_ARTICLE_HAS_ERROR', true)
      commit('SET_ADD_ARTICLE_ERROR_MESSAGE', 'Something went wrong, please try again later')
      return
    }

    await Promise.all([
      dispatch('CheckoutCart/fetchCartTotals', 'cart', {root: true}),
    ])
  }
}

const state = initialState()

const CheckoutFreeArticle = {
  namespaced: true,
  state,
  actions,
  mutations
}

export default CheckoutFreeArticle
