import Environment from "@flashpointbv/solar-ui-environment";

export type Address = {
  id?: number,
  email?: null | string,
  prefix: null | string,
  firstname: null | string,
  middlename: null | string,
  lastname: null | string,
  postcode: null | string,
  street: null | string[],
  city: null | string,
  telephone: null | string,
  company: null | string,
  country_id: string,
  region?: null | string,
  region_code?: null | string,
  region_id?: null | number,
}

export type CustomerAddress = Address & {
  customer_id?: number,
  customer_address_id?: number,
  save_in_address_book?: number,
}

export type CustomerBillingAddress = CustomerAddress & {
  default_billing?: number,
}

export type CustomerShippingAddress = CustomerAddress & {
  default_shipping?: number,
  same_as_billing?: number,
}

export const createAddressFromSource = (source: object): CustomerAddress => {
  return {
    id: source['id'] || null,
    customer_id: source['customer_id'] || null,
    customer_address_id: source['customer_address_id'] || null,
    email: source['email'] || null,
    prefix: source['prefix'] || '',
    firstname: source['firstname'] || '',
    middlename: source['middlename'] || null,
    lastname: source['lastname'] || '',
    postcode: source['postcode'] || null,
    street: 'string' === typeof source['street'] ? source['street'].split(/\n/) : (source['street'] || ['', '', '']),
    city: source['city'] || null,
    telephone: source['telephone'] || null,
    company: source['company'] || null,
    country_id: source['country_id'] || Environment().get('default_country_code'),
    region: source['region'] || null,
    region_code: source['region_code'] || null,
    region_id: source['region_id'] || null,
    save_in_address_book: source['newAddress'] ? 1 : null,
  }
}

export const emptyAddressByCountryId = (countryId: string, additional: object = {}): CustomerShippingAddress => {
  return {
    prefix: null,
    firstname: null,
    middlename: null,
    lastname: null,
    postcode: null,
    street: [null, null, null],
    city: null,
    telephone: null,
    company: null,
    region: null,
    region_code: null,
    region_id: null,
    country_id: countryId,
    ...additional
  }
}
