import Vue from 'vue';
import Vuex from 'vuex';
import MobileDetect from 'mobile-detect';
import Environment from '@flashpointbv/solar-ui-environment';
import {EnvironmentContainer} from '@flashpointbv/solar-ui-environment/dist/EnvironmentContainer';
import {AxiosInstance} from 'axios';
import {assetHelper as AssetHelper, AssetHelperInterface} from './shared/helpers/theme-assets';
import {generalHelper as GeneralHelper, GeneralHelperInterface} from './shared/helpers/general';
import {cdnHelper, CdnHelperInterface} from './shared/helpers/cdn';
import {UICoreCookies} from "@flashpointbv/solar-ui-core";
import MageApiClient from "./shared/transport/magento/MageApiClient";
import SolarApiClient from "./shared/transport/solar/SolarApiClient";
import SolarStatefulClient from "./shared/transport/solar/SolarStatefulClient";

declare module 'vue/types/vue' {
  interface Vue {
    $mobileDetect: MobileDetect
    $mageClient: AxiosInstance,
    $solarClient: AxiosInstance,
    $solarStatefulClient: AxiosInstance,
    $eventBus: Vue,
    $config: EnvironmentContainer,
    $assetHelper: AssetHelperInterface,
    $cdnHelper: CdnHelperInterface
    $generalHelper: GeneralHelperInterface
  }
}

declare module 'vuex/types' {
  interface Store<S> {
    $mageClient: AxiosInstance,
    $solarClient: AxiosInstance,
    $solarStatefulClient: AxiosInstance,
    $config: EnvironmentContainer
  }
}

const convertPrice = (value: number, splitDecimals: boolean = false): string => {
  const locale = window.Locale.replace('_', '-');
  const currency = Environment().get('display_currency_code') || 'EUR';

  let priceString = value.toLocaleString(
      locale,
      {style: 'currency', currency: currency}
  );

  if (locale === 'nl-NL') {
    priceString = priceString.replace(',00', ',-');
  }

  if (locale === 'sv-SE') {
    priceString = priceString.replace(',00', '');
  }

  if (splitDecimals) {
    priceString = priceString.replace(/(\d+),(\d+)/, '$1<span class="--decimal">$2</span>');
  }

  return priceString;
};

/**
 * Price format prototype
 */
Number.prototype['priceFormat'] = function (splitDecimals: boolean = false): string {
  return convertPrice(this, splitDecimals);
};

/**
 * Price format prototype
 */
String.prototype['priceFormat'] = function (splitDecimals: boolean = false): string {
  return convertPrice(parseFloat(this), splitDecimals);
};

/**
 * Stripslashes prototype
 */
String.prototype['stripslashes'] = function (): string {
  return this.replace(/\\(.)/mg, "$1");
};

/**
 * Check for mobile devices prototype
 */
Vue.prototype.$mobileDetect = new MobileDetect(window.navigator.userAgent);

/**
 * Inject event bus
 */
Vue.prototype.$eventBus = new Vue;

/**
 * Set clients on both Vue & Vuex
 */
const mageClient: AxiosInstance = MageApiClient
const solarClient: AxiosInstance = SolarApiClient
const solarStatefulClient: AxiosInstance = SolarStatefulClient

solarStatefulClient.interceptors.request.use(
  (config) => {
    const token = new UICoreCookies().getCookie('XSRF-TOKEN')
    if (token) {
      config.headers['X-XSRF-TOKEN'] = token
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  }
);

Vue.prototype.$mageClient = mageClient;
Vue.prototype.$solarClient = solarClient;
Vue.prototype.$solarStatefulClient = solarStatefulClient;
Vue.prototype.$config = Environment();
Vue.prototype.$assetHelper = AssetHelper();
Vue.prototype.$cdnHelper = cdnHelper;
Vue.prototype.$generalHelper = GeneralHelper();

Vuex.Store.prototype.$mageClient = mageClient;
Vuex.Store.prototype.$solarClient = solarClient;
Vuex.Store.prototype.$solarStatefulClient = solarStatefulClient;
Vuex.Store.prototype.$config = Environment();
